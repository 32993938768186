<template>
    <section id="description-list-alignment">
        <b-row class="match-height justify-content-center">
            <b-col cols="6">
                <b-card>
                    <b-row>
                        <b-col cols="12" style="padding-top: 5px;padding-bottom: 5px;">
                            {{ $t('Full Name') }}
                                <b-input-group class="input-group-merge" style="padding-top: 5px;padding-bottom: 5px;">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="vi-first-name" v-model="full_name"  />
                                </b-input-group>
                        </b-col>
                        <b-col cols="12" style="padding-top: 5px;padding-bottom: 5px;">
                                {{ $t('Email') }}
                                <b-input-group class="input-group-merge" style="padding-top: 5px;padding-bottom: 5px;">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="LifeBuoyIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="vi-email" type="email" v-model="email"  />
                                </b-input-group>
                        </b-col>
                        <b-col cols="12" style="padding-top: 5px;padding-bottom: 5px;">
                                {{ $t('Subject') }}
                                <b-input-group class="input-group-merge" style="padding-top: 5px;padding-bottom: 5px;">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MailIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="vi-email" type="text" v-model="subject"/>
                                </b-input-group>
                        </b-col>
                        <b-col cols="12" style="padding-top: 5px;padding-bottom: 5px;">
                                {{ $t('Mobile') }}
                                <b-input-group class="input-group-merge" style="padding-top: 5px;padding-bottom: 5px;">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="SmartphoneIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="vi-mobile" type="number" v-model="phone"  />
                                </b-input-group>
                        </b-col>
                        <b-col cols="12" style="padding-top: 5px;padding-bottom: 5px;">
                                {{ $t('Message') }}
                            <b-form-textarea style="padding-top: 5px;padding-bottom: 5px;"
                                id="textarea-default"
                                rows="3"
                                v-model="message"
                                />
                        </b-col>
                        <b-col cols="12" style="padding-top: 10px;padding-bottom: 10px;">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" @click="sent" variant="primary"
                                class="mr-1">
                                {{ $t('Submit') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        
    <b-toast
        id="success"
        auto-hide-delay="10055555500"
      >
        <template #toast-title >
          <div class="d-flex flex-grow-1 align-items-center mr-25" >
            <strong class="mr-auto">{{ $t('toastTitle') }}</strong>
            <small class="text-muted">{{ $t('Now') }}</small>
          </div>
        </template>
        <span>{{ $t('toastBody') }}</span>
      </b-toast>
    </section>
</template>
  
<script>
import { filesUrl, Url } from "@/main.js";
import BCardCode from '@core/components/b-card-code'
import {
    BCard,BFormTextarea,
    BRow, BCol, BFormGroup,BToast, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BCardCode,
        BRow, BCard,
        BCol,BFormTextarea,
        BFormGroup,BToast,
        BFormInput,
        BFormCheckbox,
        BInputGroup,
        BInputGroupPrepend,
        BForm,
        BButton,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            full_name:"",
            email:"",
            subject:"",
            message:"",
            phone:""
        }
    },
    methods: {
        async sent() {
            
            let data = new FormData();
            data.append("full_name", this.full_name);
            data.append("email", this.email);
            data.append("subject", this.subject);
            data.append("message", this.message);
            data.append("phone", this.phone);
            data.append("is_journal", this.phone);
            console.log(data)
            await fetch(
                `${Url}contact/clint`,
                {
                    method: 'POST',
                    headers: {
                        "Accept-Language": this.$i18n.locale,
                    },
                    body:data,
                }
            )
                .then(response => response.json())
                .then(data => {
                this.makeToast(
                    "success",
                    "Success",
                    "The request has been sent successfully"
                ); 
                    this.resetData()
                }).catch(error => {
                    this.makeToast(
                    "warning",
                    "Wrong",
                    "There was an error sending the request, all fields must be filled"
                ); 
            });;
        },
        resetData(){
            this.full_name="",
            this.email="",
            this.subject="",
            this.message="",
            this.phone=""
        },
        makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    }
}
</script>
<!-- this.makeToast(
    "success",
    "Delete Success",
    "Your item is deleted successfully"
  ); -->
  <!-- this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            ); -->